<template>
  <div>
    <panel-description
      :paymentMethodName="paymentMethodName"
      :virtualAccountNumber="virtualAccountNumber"
      :viewMybookingUrl="viewMybookingUrl"
      :price="price"
      :orderNumber="orderNumber"
      :actions="actions"
      :orderUuid="orderUuid"
    />
    <div class="container py-3" id="checkout-thankyou">
      <countdown :paymentDueDiff="paymentDueDiff" class="mt-4"></countdown>
      <!--      <panel-steps-->
      <!--        :content="content"-->
      <!--        :viewMybookingUrl="viewMybookingUrl"-->
      <!--        v-if="content"-->
      <!--      ></panel-steps>-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Countdown from '@/components/how-to-pay/partials/countdown';
import PanelDescription from '@/components/how-to-pay/partials/panel-description';
// import PanelSteps from '@/components/how-to-pay/partials/panel-steps';
export default {
  name: 'credit-card',
  props: [
    'paymentMethodName',
    'price',
    'virtualAccountNumber',
    'viewMybookingUrl',
    'orderNumber',
    'actions',
    'paymentDueDiff',
    'orderUuid',
  ],
  components: {
    Countdown,
    PanelDescription,
    // PanelSteps,
  },
  computed: {
    ...mapState({
      bookingOrder: (state) => state.v2.booking.bookingOrder,
    }),
  },
  data: () => ({
    content: null,
  }),
  mounted() {
    this.content = {
      tabs: [
        {
          title: this.$t('booking.guide.payment') + this.paymentMethodName,
          steps: !this.$device.isDesktop
            ? [
                this.$t('booking.guide.gopayM.msg1'),
                this.$t('booking.guide.gopayM.msg2'),
                this.$t('booking.guide.gopayM.msg3'),
                this.$t('booking.guide.gopayM.msg4'),
              ]
            : [
                this.$t('booking.guide.gopay.msg1'),
                this.$t('booking.guide.gopay.msg2'),
                this.$t('booking.guide.gopay.msg3'),
                this.$t('booking.guide.gopay.msg4'),
              ],
        },
      ],
    };
  },
};
</script>

<style scoped></style>
